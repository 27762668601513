import { Button } from '@mehilainen/design-system'
import React from 'react'
import styled from 'styled-components'

type Props = {
  children: JSX.Element | JSX.Element[] | string
  loading: boolean
  onClick: () => void
}
export default ({ children, loading, onClick }: Props) => {
  return (
    <StyledButton onClick={onClick} loading={loading} type="button">
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  color: ${props => props.theme.colors.warning.text};
  background: ${props => props.theme.colors.warning.background};
  border-color: ${props => props.theme.colors.warning.border};
  :hover {
    background: ${props => props.theme.colors.hover.listBackground};
  }
  margin-top: 20px;
`
