import { MagentoCartItem } from 'requests/graphql/fragments/cartFragment'

const cartItemToProduct = (cartItem: MagentoCartItem): Product & Quantity => {
  return {
    categories: cartItem.product.categories,
    name: cartItem.product.name,
    sku: cartItem.product.sku,
    url_key: cartItem.product.url_key,
    gift_card_amounts: JSON.stringify([
      { amount: cartItem.prices.price.value },
    ]),
    price_range: cartItem.product.price_range,
    quantity: cartItem.quantity,
  }
}

export default cartItemToProduct
