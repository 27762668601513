import React from 'react'
import { Select } from '@mehilainen/design-system'
import styled from 'styled-components'

interface Props {
  countries: Country[]
  countryId?: string
  setCountryId: (value: string) => void
}

interface Option {
  label: string
  value: string
}

export type Country = {
  id: string
  full_name_locale: string
}

export default ({ countries, countryId, setCountryId }: Props) => {
  const options: Option[] = []

  countries.forEach(country => {
    if (country.id === 'FI') return
    options.push({
      value: country.id,
      label: country.full_name_locale,
    })
  })

  const sortedOptions = options.sort((a, b) => 0 - (a.label > b.label ? -1 : 1))

  // force suomi to beginning of list
  sortedOptions.unshift({
    value: 'FI',
    label: 'Suomi',
  })

  return (
    <>
      <SelectLabel>Maa *</SelectLabel>
      <CustomSelect
        options={sortedOptions}
        value={
          options ? options.find(option => option.value === countryId) : ''
        }
        placeholder=""
        onChange={(option: Option) => setCountryId(option.value)}
      />
    </>
  )
}

const CustomSelect = styled(Select)`
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif !important;
`

const SelectLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
`
