import { Button, H1, H3 } from '@mehilainen/design-system'
import React, { useContext, useEffect, useState } from 'react'
import {
  emptyAllFromCartGA,
  loginGA,
  registrationGA,
  viewCartGA,
} from 'gtag.js/utils'

import { BsInfoCircle } from 'react-icons/bs'
import ButtonWarning from 'components/ButtonWarning'
import { CartContext } from 'state/providers/CartProvider'
import CartProductList from 'components/CartProductList'
import ContentContainer from 'components/ContentContainer'
import { Country } from 'components/CountrySelect'
import CreateCustomerForm from 'components/forms/CreateCustomerForm'
import { LoginContext } from 'state/providers/LoginProvider'
import { LoginData } from 'requests/magento_login/loginCustomer'
import LoginForm from 'components/forms/LoginForm'
import { NewCustomerData } from 'requests/magento_customer/createCustomer'
import { NotificationContext } from 'state/providers/NotificationProvider'
import OrderSummary from 'components/OrderSummary'
import PayerInfo from 'components/PayerInfo'
import { Tab } from './Profile'
import TabSwitcher from 'components/TabSwitcher'
import { cartItemToProduct } from 'utils/cart'
import { getAuthenticationError } from './Login'
import { giftCardSku } from './ProductGiftCard'
import { graphql } from 'gatsby'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { unexpectedErrorDefaultMessage } from 'utils/errorHandling'

interface Props {
  data: {
    magento: {
      countries: Country[]
    }
  }
}

export default ({ data }: Props) => {
  const [activeTab, setActiveTab] = useState<number>(1)
  const [anonymousOrder, setAnonymousOrder] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { countries } = data.magento
  const { cartContent, resetCustomerCart, resetGuestCart } =
    useContext(CartContext)
  const {
    customerToken,
    isLoggedIn,
    loginCustomer,
    logoutCustomer,
    createNewCustomer,
  } = useContext(LoginContext)
  const { setNotification } = useContext(NotificationContext)

  // TODO: ask for confirmation before removing
  const resetCartHanlder = async (): Promise<void> => {
    cartContent.items &&
      emptyAllFromCartGA(
        cartContent.items.map(item => cartItemToProduct(item)),
        giftCardSku
      )
    setLoading(true)
    try {
      if (!customerToken) {
        await resetGuestCart()
      } else {
        await resetCustomerCart(customerToken)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error('Faied resetting cart content', err)
    }
  }

  //TODO: move to own hook
  const handleLogin = async ({ email, password }: LoginData): Promise<void> => {
    setLoading(true)
    let newCustomerToken
    try {
      newCustomerToken = await loginCustomer(password, email)
      loginGA()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      newCustomerToken && logoutCustomer(newCustomerToken)
      let message = unexpectedErrorDefaultMessage
      if (err instanceof Error) {
        message = getAuthenticationError(err)
      }
      setNotification(
        {
          type: 'error',
          message,
        },
        7000
      )
      console.error('Customer login failed', err)
      return
    }
    navigate('/cart')
  }

  // TODO: move to own hook
  const createCustomer = async (
    newCustomerData: NewCustomerData
  ): Promise<void> => {
    setLoading(true)
    const { email, password } = newCustomerData
    try {
      await createNewCustomer(newCustomerData)
      registrationGA()
      setLoading(false)
      handleLogin({ password, email })
    } catch (err) {
      setLoading(false)
      setNotification(
        {
          type: 'error',
          message: unexpectedErrorDefaultMessage,
        },
        5000
      )
      console.error('Failed creating new customer', err)
    }
  }

  useEffect(() => {
    cartContent.items &&
      viewCartGA(
        cartContent.items.map(item => cartItemToProduct(item)),
        giftCardSku
      )
  }, [cartContent.items])

  return (
    <>
      <ContentContainer>
        <Title>Ostoskori</Title>
        {!isLoggedIn && !anonymousOrder ? (
          <Grid activeTab={activeTab}>
            <TopInfo>
              <p>
                Voit jatkaa ostoksesi loppuun myös kirjautumatta verkkokauppaan.
              </p>
              <p>
                <Button onClick={() => setAnonymousOrder(true)} id="no-login">
                  Jatka kirjautumatta
                </Button>
              </p>
            </TopInfo>
            <TabSwitcher>
              <Tab
                large
                onClick={() => setActiveTab(1)}
                $active={activeTab === 1}
              >
                Kirjaudu sisään
              </Tab>
              <Tab
                large
                onClick={() => setActiveTab(2)}
                $active={activeTab === 2}
              >
                Luo uusi tili
              </Tab>
            </TabSwitcher>
            {activeTab === 1 ? (
              <LoginFormContainer>
                <LoginForm
                  loading={loading}
                  heading="Palaava asiakas"
                  handleLogin={handleLogin}
                />
              </LoginFormContainer>
            ) : (
              <CreateCustomerForm
                loading={loading}
                heading="Henkilökohtaiset tiedot"
                createCustomer={createCustomer}
              />
            )}
          </Grid>
        ) : (
          <Columns>
            <div>
              <Subtitle>Maksutapa</Subtitle>
              <Paytrail>
                Paytrail <BsInfoCircle />
              </Paytrail>
              <PayerInfo countries={countries} cartContent={cartContent} />
            </div>
            <div>
              <Subtitle>Ostoskorin sisältö</Subtitle>
              {((!cartContent.items || !cartContent.items.length) &&
                'Ei tuotteita') ||
                (cartContent.items &&
                  cartContent.items.length &&
                  cartContent.items[0].quantity > 0 && (
                    <>
                      <CartProductList cartContent={cartContent} />
                      <ButtonWarning
                        onClick={resetCartHanlder}
                        loading={loading}
                      >
                        Tyhjennä ostoskori
                      </ButtonWarning>
                    </>
                  ))}
              <Subtitle>Tilauksen yhteenveto</Subtitle>
              <OrderSummary
                total={cartContent.prices?.grand_total.value ?? 0}
              />

              {/* Not used for now */}
              {/* 
            <Subtitle>Alennuskoodi</Subtitle>

            <Discount>
              <Input
                id="discountCode"
                label="Syötä koodi"
                required
                value={discountCode}
                onChange={e => setDiscountCode(e.target.value)}
                type="text"
              />
              <Button disabled={!discountCode} secondary>
                Aktivoi koodi
              </Button>
            </Discount> */}
            </div>
          </Columns>
        )}
      </ContentContainer>
    </>
  )
}

const Grid = styled.div<{ activeTab: number }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px 0;
`

const TopInfo = styled.div`
  text-align: center;
  button {
    margin: 16px auto 0;
  }
`

const Title = styled(H1)`
  text-align: center;
`

const Subtitle = styled(H3)`
  padding: 20px 0;
`

const LoginFormContainer = styled.div`
  margin: auto;
`

const Columns = styled.div`
  display: flex;
  flex-direction: column-reverse;

  > div {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    gap: 50px;
    justify-content: center;
    flex-direction: row;

    > div {
      max-width: 550px;
    }
  }
`

const Paytrail = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    color: #0499fb;
  }
`

export const query = graphql`
  query Cart {
    magento {
      countries {
        id
        full_name_locale
      }
    }
  }
`
