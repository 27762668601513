import { Button, Checkbox, H3 } from '@mehilainen/design-system/lib/components/'
import CountrySelect, { Country } from './CountrySelect'
import { Link, navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { addPaymentInfoGA, beginCheckoutGA } from 'gtag.js/utils'

import { CartContent } from 'state/reducers/cartReducer'
import { CartContext } from 'state/providers/CartProvider'
import { CustomerContext } from 'state/providers/CustomerProvider'
import { EmailField } from './forms/fields/EmailField'
import { LoginContext } from 'state/providers/LoginProvider'
import { NameField } from './forms/fields/NameField'
import { NotificationContext } from 'state/providers/NotificationProvider'
import { TextField } from './forms/fields/TextField'
import { cartItemToProduct } from 'utils/cart'
import { giftCardSku } from 'templates/ProductGiftCard'
import styled from 'styled-components'
import { unexpectedErrorDefaultMessage } from 'utils/errorHandling'
import { useCookies } from 'react-cookie'

type PayerInfoProps = {
  countries: Country[]
  cartContent: CartContent
}

const PayerInfo = ({ countries, cartContent }: PayerInfoProps) => {
  const { customerInfo } = useContext(CustomerContext)
  const { cartId, payOrder } = useContext(CartContext)
  const { customerToken } = useContext(LoginContext)
  const { setNotification } = useContext(NotificationContext)

  const [email, setEmail] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [street, setStreet] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [countryId, setCountryId] = useState<string>('')
  const [postcode, setPostcode] = useState<string>('')
  const [telephone, setTelephone] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [emailInvalid, setEmailInvalid] = useState<boolean | undefined>(
    undefined
  )
  const [activeTab, setActiveTab] = useState<number>(1)
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false)

  const [, setCartCookie] = useCookies(['cart'])
  const [, setTokenCookie] = useCookies(['token'])

  const disabled =
    !email ||
    emailInvalid ||
    !firstName.trim() ||
    !lastName.trim() ||
    !street.trim() ||
    !city.trim() ||
    !countryId ||
    !postcode.trim() ||
    !telephone.trim()

  // TODO allow sending order only if cart is not empty
  // If cart is empty, Magento returns an error of shipping address missing
  // and the user sees the generic error message
  const sendOrder = async () => {
    setLoading(true)
    const customerData = {
      email,
      firstName,
      lastName,
      street: [street],
      city,
      countryId,
      postcode,
      telephone,
      company,
    }
    try {
      const sendOrderSuccess = await payOrder(customerData, customerToken)
      if (sendOrderSuccess === true) {
        const backend_endpoint = process.env.GATSBY_MAGENTO_BACKEND_ENDPOINT
          ? process.env.GATSBY_MAGENTO_BACKEND_ENDPOINT
          : ''
        const domain = `.${process.env.GATSBY_MAGENTO_COOKIE_DOMAIN}`
        const backend_endpoint_dev_path = `/headlesscheckout?cart=${cartId}${
          customerToken !== undefined ? `&token=${customerToken}` : ''
        }`
        const url =
          process.env.GATSBY_ENV === 'development'
            ? `${backend_endpoint}${backend_endpoint_dev_path}`
            : `${backend_endpoint}/headlesscheckout`
        setCartCookie('cart', cartId, {
          path: '/',
          secure: true,
          sameSite: 'none',
          domain,
        })
        if (customerToken === undefined) {
          setTokenCookie('token', '', {
            path: '/',
            secure: true,
            sameSite: 'none',
            domain,
          })
        } else {
          setTokenCookie('token', customerToken, {
            path: '/',
            secure: true,
            sameSite: 'none',
            domain,
          })
        }
        setLoading(false)
        navigate(url)
      }
    } catch (err) {
      setLoading(false)
      setNotification(
        {
          type: 'error',
          message: unexpectedErrorDefaultMessage,
        },
        5000
      )
      console.error('Failed sending order to Magento', err)
    }
    setLoading(false)
  }

  useEffect(() => {
    document && document.querySelector('input')?.focus()
    // Focus on the input but make the top of the page visible too
    activeTab === 2 && window && window.scrollTo(0, 100)
  }, [activeTab])

  useEffect(() => {
    cartContent.items &&
      beginCheckoutGA(
        cartContent.items.map(item => cartItemToProduct(item)),
        giftCardSku
      )
    cartContent.items &&
      addPaymentInfoGA(
        cartContent.items.map(item => cartItemToProduct(item)),
        giftCardSku,
        'Paytrail'
      )
    customerInfo.firstname && setFirstName(customerInfo.firstname)
    customerInfo.lastname && setLastName(customerInfo.lastname)
    customerInfo.email && setEmail(customerInfo.email)
    const address = customerInfo.addresses && customerInfo.addresses[0]
    if (address) {
      address.telephone && setTelephone(address?.telephone)
      address.street && setStreet(address?.street[0])
      address.postcode && setPostcode(address?.postcode)
      address.city && setCity(address.city)
      address.country_code && setCountryId(address.country_code)
    }
  }, [cartContent.items, customerInfo])

  // TODO consider changeing the tabbing logic, why using tabbing when we are not showing the tab to the user?
  return (
    <>
      <Subtitle>Ostajan tiedot</Subtitle>
      {activeTab === 1 ? (
        <PayerForm>
          <EmailField
            email={email}
            setEmail={setEmail}
            emailInvalidOrUnavailable={emailInvalid}
            setEmailInvalidOrUnavailable={setEmailInvalid}
            label="Ostajan sähköpostiosoite *"
          />
          <NameField
            name={firstName}
            nameType="FIRSTNAME"
            setName={setFirstName}
          />
          <NameField
            name={lastName}
            nameType="LASTNAME"
            setName={setLastName}
          />
          <TextField
            id="company"
            label="Yritys"
            value={company}
            setValue={setCompany}
          />
          <TextField
            id="street"
            label="Katuosoite"
            value={street}
            setValue={setStreet}
            required
          />
          <TextField
            id="postcode"
            label="Postinumero"
            required
            value={postcode}
            setValue={setPostcode}
          />
          <TextField
            id="city"
            label="Kaupunki"
            required
            value={city}
            setValue={setCity}
          />
          <CountrySelect
            countries={countries}
            setCountryId={setCountryId}
            countryId={countryId}
          />
          <TextField
            id="telephone"
            label="Puhelinnumero"
            required
            value={telephone}
            setValue={setTelephone}
            type="tel"
          />

          <NextButton>
            <Button
              disabled={disabled}
              onClick={e => {
                e.preventDefault()
                setActiveTab(2)
              }}
            >
              Seuraava
            </Button>
          </NextButton>
        </PayerForm>
      ) : (
        <Summary>
          <span>{email}</span>
          <span>
            {firstName} {lastName}
          </span>
          {company ? <span>{company}</span> : undefined}
          <span>
            {street}, {postcode} {city}
          </span>
          <span>
            {
              countries.find(country => country.id === countryId)
                ?.full_name_locale
            }
          </span>
          <span>{telephone}</span>
          <EditButton>
            <Button secondary onClick={() => setActiveTab(1)}>
              Muokkaa
            </Button>
          </EditButton>
          <TermsAndConditions
            checked={termsAndConditions}
            id="terms-and-conditions"
            onChange={() => setTermsAndConditions(!termsAndConditions)}
          >
            Hyväksyn Mehiläinen Oy:n yleiset{' '}
            <a
              href="https://media.mehilainen.fi/www/pdf/yleiset_verkkokaupan_sopimus-_ja_toimitusehdot.pdf"
              target="_blank"
              className="apply-link-style"
              rel="noreferrer"
            >
              verkkokaupan sopimus- ja toimitusehdot
            </a>{' '}
            sekä Mehiläisen terveyspalveluita koskevat{' '}
            <a
              href="https://www.mehilainen.fi/terveyspalveluiden-yleiset-ehdot"
              target="_blank"
              className="apply-link-style"
              rel="noreferrer"
            >
              yleiset ehdot
            </a>
          </TermsAndConditions>

          <Buttons>
            <Link to="/">
              <Button secondary>Jatka ostoksia</Button>
            </Link>
            <Button
              loading={loading}
              disabled={!termsAndConditions}
              onClick={() => sendOrder()}
            >
              Siirry maksamaan
            </Button>
          </Buttons>
        </Summary>
      )}
    </>
  )
}

export default PayerInfo

const Buttons = styled.div`
  display: flex;
  gap: 25px;

  > a,
  button {
    width: 100%;
  }
`

const EditButton = styled.div`
  margin-top: 15px;

  > button {
    width: calc(50% - 15px);
  }
`
const NextButton = styled.div`
  text-align: right;
  margin-top: 20px;
`

const PayerForm = styled.form`
  display: grid;
  grid-template-columns: minmax(min(80vw, 388px), 100%);
  gap: 8px 0;
`

const Subtitle = styled(H3)`
  padding: 20px 0;
`

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    font-weight: 500;
  }
`

const TermsAndConditions = styled(Checkbox)`
  margin: 20px 0;
`
