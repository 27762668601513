import { CartContent } from 'state/reducers/cartReducer'
import React from 'react'
import styled from 'styled-components'

interface Props {
  cartContent: CartContent
}

export default ({ cartContent }: Props) => {
  return !isEmpty(cartContent) ? (
    <CartContentContainer>
      {cartContent.items &&
        cartContent.items.map((item, index) => (
          <ProductRow key={index}>
            <div>
              <Quantity>{item.quantity} x</Quantity>{' '}
              <span>{item.product.name}</span>
            </div>
            <span>{Format(item.prices.price.value)} €</span>
          </ProductRow>
        ))}
    </CartContentContainer>
  ) : (
    <CartContentContainer>Ostoskorisi on tyhjä</CartContentContainer>
  )
}

const Format = (number: number) => {
  return number.toFixed(2).replace('.', ',')
}

const isEmpty = (cartContent: CartContent) => {
  return (
    !cartContent ||
    !cartContent.items ||
    (cartContent.items.length === 1 && cartContent.items[0].quantity === 0)
  )
}

const CartContentContainer = styled.div`
  font-size: clamp(0.8rem, 1vw, 1.1rem);
  padding: 15px;
  background: #fbfbfb;
  box-shadow: 2px 2px 12px rgba(96, 96, 96, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  gap: 4px;
`

const Quantity = styled.span`
  color: ${props => props.theme.primaryGreen};
`
