import React, { useState } from 'react'
import { capitalizeFirstLetter, checkMark } from 'utils/forms'

import HelperText from '../layouts/HelperText'
import { Input } from '@mehilainen/design-system'
import InputContainer from '../layouts/InputContainer'

type Props = {
  label: string
  value: string
  setValue: (value: string) => void
  disabled?: boolean
  required?: boolean
  id?: string
  type?: 'text' | 'tel'
}

export const TextField = (props: Props) => {
  const { disabled, label, required, setValue } = props
  const [helperText, setHelperText] = useState<string>('')
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [id] = useState<string>(props.id ? props.id : label)
  const [type] = useState<string>(props.type ? props.type : 'text')

  const capitalLabel = capitalizeFirstLetter(label)

  const isNameError = (text: string, disabled?: boolean): boolean | undefined =>
    disabled ? undefined : !text.trim()

  function handleNameInputChange(value: string): void {
    setValue(value)
    if (required) {
      if (!value.trim()) {
        // Visually hidden
        setHelperText(`${capitalLabel} ei voi olla tyhjää`)
      } else if (!props.value.trim()) {
        // Visually hidden
        setHelperText(`${capitalLabel} on oikean muotoinen`)
      }
    }
    return
  }

  return (
    <InputContainer>
      <Input
        id={id}
        value={props.value}
        label={
          checkMark(
            (hasFocus && isNameError(props.value, disabled)) || undefined
          ) + `${capitalLabel} ${required ? '*' : ''}`
        }
        required={required}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onChange={e => handleNameInputChange(e.target.value)}
        type={type}
        disabled={disabled}
        error={hasFocus && required && !props.value.trim()}
      />
      <HelperText
        className="visually-hidden"
        error={hasFocus && required === true && !props.value.trim()}
        htmlFor={`${label}-input`}
      >
        {helperText}
      </HelperText>
    </InputContainer>
  )
}

export type NameType = 'FIRSTNAME' | 'LASTNAME' | 'NAME'
